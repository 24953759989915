import { Injectable } from "@angular/core";
import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppInsightsService {
  instance!: ApplicationInsights;

  constructor() {
    this.initializeAppInsights();
  }

  /**
   * Initializes Application Insights with the environment-specific instrumentation key and config.
   */
  private initializeAppInsights(): void {
    try {
      this.instance = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          enableAutoRouteTracking: true, // Automatically tracks route changes (Angular routes)
          enableCorsCorrelation: true, // Enables cross-origin resource sharing (CORS) correlation
          distributedTracingMode: 1, // Enables W3C Distributed Tracing
        },
      });

      this.instance.loadAppInsights();

      const telemetryInitializer = (envelope: ITelemetryItem) => {
        if (envelope.tags) envelope.tags["ai.cloud.role"] = "ADMIN_FRONTEND";
      };

      this.instance.addTelemetryInitializer(telemetryInitializer);
      this.instance.trackPageView();
      // Automatically track the page view
      this.instance.trackPageView();
    } catch (error) {
      console.error("Failed to initialize Application Insights:", error);
    }
  }
}
