<div class="align">
  <button
    mat-fab
    color=""
    type="button"
    aria-label="Back to top of page"
    class="back-to-top-button"
    [@appearInOut]="animationState"
    (click)="scrollTop($event)"
  >
    <mat-icon>arrow_upward</mat-icon>
  </button>
</div>
