import { Component, Inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-snackbar-success",
  standalone: true,
  templateUrl: "./snackbar-success.component.html",
  styleUrls: ["./snackbar-success.component.scss"],
  imports: [MatIconModule],
})
export class SnackbarSuccessComponent {
  showSuccessfully = true;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarSuccessComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data?: any
  ) {
    if (
      data.message.substring(0, 2) === "No" ||
      data.action == "Informational"
    ) {
      this.showSuccessfully = false;
    }
  }
}
