import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
  imports: [CommonModule, MatCardModule, MatProgressSpinnerModule],
})
export class PageNotFoundComponent implements OnInit {
  loading: boolean = true;
  displayedName: string | null = null;
  userType: string | null = null;
  buttonText: string | null = null;

  constructor(private router: Router) {}

  ngOnInit() {
    this.getUserType();
  }

  getUserType(): void {
    this.buttonText = "Return to application";
    this.loading = false;
  }

  redirect(): void {
    if (this.userType === "applicant") {
      this.router.navigateByUrl(`/`);
    }
  }
}
