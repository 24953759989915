// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnv } from "./environment.common";

const clientId = "f36d58c0-2645-4b3f-8285-331bbdf0db87";
const tenantName = "iaptstb2c";
// const tenantId = " 3ddc4284-8377-4878-94bf-a35840ca61fe";
const scopes = [clientId];
const appUrl = "https://intl-admin-tst.senecapolytechnic.ca";
const instrumentationKey = "";
const policy = commonEnv.agentSignInPolicy; // Your sign-in policy
const azureInstance = commonEnv.azureInstance;

export class BuildEnvironment {
  constructor() {}

  public static GetEnvironmentUrl(): string {
    return environment.apiConfig.apiUrl;
  }
}

export const environment = {
  apiSubscriptionKeyName: "ADMNPRTL-Apim-Subscription-Key",
  apiSubscriptionKey: "c2f838a164a54842a95c42a58e41476e",
  apiSubscriptionEnabled: true,
  production: false,
  msalConfig: {
    auth: {
      clientId: clientId,
      authority: `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${policy}`,
      redirectUri: `${appUrl}/`,
      postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/logout`,
      knownAuthorities: [`${tenantName}.b2clogin.com`], // array of URIs that are known to be valid,
      // validateAuthority: true,
      // navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  apiConfig: {
    scopes: scopes,
    uri: `${appUrl}`,
    apiUrl:
      "https://seneapi-apims-tst.azure-api.net/adminportal/public/controllers/api",
  },
  appInsights: {
    instrumentationKey: instrumentationKey,
    scopes: [clientId],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
