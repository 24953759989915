import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

//---------dialog------------------/
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  CustomBox,
  DialogComponent,
} from "../components/dialog/dialog.component";
import { SnackbarSuccessComponent } from "../components/snackbar-success/snackbar-success.component";
@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  MessageBox(
    message: string,
    action: string,
    duration: number,
    backgroundColor: string
  ) {
    // this._snackBar.open(message, action, {
    //   duration: 0,
    //   verticalPosition: 'top',
    //   horizontalPosition: 'center',
    //   panelClass: [BoxColorClass]
    // });
    // use custom snackbar to have icon
    this._snackBar.openFromComponent(SnackbarSuccessComponent, {
      data: {
        message: message,
        action: action,
      },
      duration: duration,
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: [backgroundColor],
    });
  }

  //--------------dialog-------//
  openDialog(
    id,
    title: string,
    message: string,
    reject: string,
    accept: string
  ) {
    const resDialog = new CustomBox(title, message, reject, accept);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "500px",
      data: resDialog,
    });

    return dialogRef;
  }

  ErrorDialog(
    dialogHeading: string,
    messages: string,
    no: string,
    yes: string
  ) {
    const res = new CustomBox(dialogHeading, messages, no, yes);

    const ref = this.dialog.open(DialogComponent, {
      maxWidth: "500px",
      data: res,
    });

    return ref;
  }

  callRedirect(route: string, time: number | undefined) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, time);
  }
}
