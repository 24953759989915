import { inject } from "@angular/core";
import {
  CanActivateFn,
  CanMatch,
  Route,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { AuthenticationService } from "./auth.service";
import { MsalService } from "@azure/msal-angular";
import { DialogService } from "src/app/shared/services/dialog.service";
import { environment } from "src/environments/environment";

export const authGuardFn: CanActivateFn = async (
  route,
  state
): Promise<boolean | UrlTree> => {
  const authService = inject(AuthenticationService);
  const msalService = inject(MsalService);
  const dialogService = inject(DialogService);

  const targetUrl = state.url;

  // Check if the user is authenticated by calling the service's method
  const isAuthenticated = await authService.isAuthenticated();

  if (!isAuthenticated) {
    msalService.instance.setActiveAccount(null); // Reset the active account if not authenticated
    msalService.instance.loginRedirect(); // Set redirect URI in case of login
    msalService.loginRedirect({
      scopes: [...environment.apiConfig.scopes],
      redirectUri: state.url, // Optionally, preserve the target URL
    });

    return false;
    // return await firstValueFrom(msalGuard.canActivate(route, state));
  }

  if (!authService.isAdmin()) {
    const res = dialogService.openDialog(
      0,
      "Access Denied Error",
      "You do not have permission to access this application",
      "OK",
      "Cancel"
    );
    res.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        return;
      }
    });
    return false;
  }

  if (targetUrl.match(`/home`)) {
    return true;
  }

  if (
    (targetUrl.match(`/content`) ||
      targetUrl.match("/agency") ||
      targetUrl.match("/commissions")) &&
    authService.hasRole("Intl_Admin")
  ) {
    return true;
  }

  if (
    targetUrl.match(`/iwa`) &&
    (authService.hasRole("IWA_Admin") || authService.hasRole("IWA_Admin_User"))
  ) {
    return true;
  }

  if (
    targetUrl.match(`/users`) ||
    (targetUrl.match("/autoeval") && authService.hasRole("ITSAccess"))
  ) {
    return true;
  }

  if (targetUrl.match("/autoeval") && authService.hasRole("Intl_Auto_Eval")) {
    return true;
  }

  const res = dialogService.openDialog(
    0,
    "Access Denied Error",
    "You do not have permission to access this menu item",
    "OK",
    "Cancel"
  );
  res.afterClosed().subscribe((dialogResult) => {
    if (dialogResult) {
      return;
    }
  });
  return false;
};

export const canMatchGuardFn = async (route: Route, segments: UrlSegment[]) => {
  const authService = inject(AuthenticationService);
  const isAuthenticated = await authService.isAuthenticated();
  return isAuthenticated;
};
