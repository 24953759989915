// src/app/app.config.ts
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { BrowserModule } from "@angular/platform-browser";
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
  withFetch,
} from "@angular/common/http";
import {
  BrowserAnimationsModule,
  provideNoopAnimations,
} from "@angular/platform-browser/animations";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
} from "@azure/msal-angular";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { environment } from "src/environments/environment";
import { APP_INITIALIZER } from "@angular/core";
import { MsalInitializerService } from "./msal-initializer.service";
import { HttpInterceptorHeaderService } from "./shared/services/httpInterceptorHeaders.service";

/**
 * Logger callback function to handle MSAL logs.
 */
export function loggerCallback(logLevel: LogLevel, message: string) {
  // switch (logLevel) {
  //   case LogLevel.Error:
  //     console.error(message);
  //     break;
  //   case LogLevel.Info:
  //     console.info(message);
  //     break;
  //   case LogLevel.Verbose:
  //     console.debug(message);
  //     break;
  //   case LogLevel.Warning:
  //     console.warn(message);
  //     break;
  //   default:
  //     break;
  // }
}

/**
 * Factory function to create the MSAL instance.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
      knownAuthorities: environment.msalConfig.auth.knownAuthorities,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false,
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

/**
 * Factory function to configure MSAL Interceptor.
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.apiConfig.apiUrl,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Factory function to configure MSAL Guard.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: "/login-failed",
  };
}

/**
 * Application configuration with providers.
 */
export const appConfig: ApplicationConfig = {
  providers: [
    // Change detection strategy
    provideZoneChangeDetection({ eventCoalescing: true }),

    // Router configuration
    provideRouter(routes),

    // Import necessary Angular and Material modules
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      MatButtonModule,
      MatToolbarModule,
      MatListModule,
      MatMenuModule,
      MsalModule
    ),

    // Noop animations (can be replaced with actual animations if needed)
    provideNoopAnimations(),

    // HTTP Client configuration with interceptors
    provideHttpClient(withInterceptorsFromDi(), withFetch()),

    // MSAL HTTP Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorHeaderService,
      multi: true,
    },

    // MSAL Instance
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },

    // MSAL Guard Configuration
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },

    // MSAL Interceptor Configuration
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },

    // MSAL Services
    MsalService,
    MsalGuard,
    MsalBroadcastService,

    // APP_INITIALIZER to initialize MSAL before the app starts
    {
      provide: APP_INITIALIZER,
      useFactory: (initializer: MsalInitializerService) =>
        initializer.initialize(),
      deps: [MsalInitializerService],
      multi: true,
    },
  ],
};
