import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { filter } from "rxjs/operators";
import { AuthenticationService } from "../shared/auth/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class HomeComponent implements OnInit {
  name: string | undefined;

  constructor(
    private msalService: MsalService,
    private authService: AuthenticationService
  ) { }

  isLoginCompleted(): boolean {
    // Check if there are any active accounts
    const accounts = this.msalService.instance.getAllAccounts();
    return accounts.length > 0;
  }

  ngOnInit(): void {
    if (this.isLoginCompleted()) {
      const userInfo = this.authService.getUser();

      if (userInfo) {
        this.name = userInfo.name;
      }
    }
  }
}
