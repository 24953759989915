import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: "root",
})
export class MsalInitializerService {
  constructor(private msalService: MsalService) {}

  initialize(): () => Promise<void> {
    return async () => {
      await this.msalService.instance.initialize();
      await this.msalService.instance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            this.msalService.instance.setActiveAccount(response.account);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }
}
