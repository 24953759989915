const applicantSignInPolicy = "B2C_1_signupsignin";
const agentSignInPolicy = "B2C_1A_signin_with_roles";

const azureInstance = `https://IAPDEVB2C.b2clogin.com`;
const azureInstance_v2 = `https://IAPDEVB2C.b2clogin.com`;

const resetPasswordPolicy = "B2C_1_resetpassword";

export const commonEnv = {
  applicantSignInPolicy,
  agentSignInPolicy,
  azureInstance,
  azureInstance_v2,
  resetPasswordPolicy,
};
