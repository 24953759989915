import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { authGuardFn } from "./shared/auth/auth.guard";
import { LogoutComponent } from "./shared/components/logout/logout.component";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
//import { ContentModule } from "AdminPortal-Web/src/app/content/content.module";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [authGuardFn],
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path:"commissions",
    canActivate: [authGuardFn],
    loadChildren: () => 
      import("./commissions/commissions-routing.module").then(
        (m) => m.CommissionsRoutingModule
      ),
  },
  {
    path: "content",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./content/content-routing.module").then(
        (m) => m.ContentRoutingModule
      ),
  },
  {
    path: "iwa",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./iwa/iwa-routing.module").then((m) => m.IwaModule),
  },
  {
    path: "agency",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./agency/agency-routing.module").then(
        (m) => m.AgencyRoutingModuleTsModule
      ),
  },
  {
    path: "autoeval",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./autoeval/autoeval-routing.module").then(
        (m) => m.AutoevalModule
      ),
  },
  {
    path: "iwa",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./iwa/iwa-routing.module").then((m) => m.IwaModule),
  },
  {
    path: "commissions",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./commissions/commissions-routing.module").then(
        (m) => m.CommissionsRoutingModule
      ),
  },
  {
    path: "users",
    canActivate: [authGuardFn],
    loadChildren: () =>
      import("./users/users-routing.module").then((m) => m.UsersRoutingModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
  {
    path: "denied",
    component: PageNotFoundComponent,
  },
];
