import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MsalService, MsalBroadcastService } from "@azure/msal-angular";
import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
} from "@azure/msal-browser";
import { Observable } from "rxjs";
import { IAuthUser, IMsalStoredToken, IUser } from "./auth.model";
import { environment } from "src/environments/environment";
import { AppInsightsService } from "src/app/app-insights.service";
import { CODE_CHALLENGE } from "@azure/msal-common/lib/types/constants/AADServerParamKeys";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private authUser: IUser | null | undefined;

  constructor(
    private httpClient: HttpClient,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appInsights: AppInsightsService
  ) {}

  /**
   * Acquires the access token silently using MSAL 3.0.
   * @returns A Promise that resolves to an access token.
   */
  async acquireToken(): Promise<AuthenticationResult> {
    console.log("acquiring token");
    const request = {
      scopes: [environment.msalConfig.auth.clientId],
    };

    try {
      return await this.msalService.instance.acquireTokenSilent(request);
    } catch (error) {
      console.error(
        "Silent token acquisition failed; acquiring token interactively",
        error
      );
      return await this.msalService.instance.acquireTokenPopup(request);
    }
  }

  /**
   * Verifies access by calling the backend API.
   * @returns An Observable of the authenticated user.
   */
  verifyAccess = (): Observable<IAuthUser> => {
    return this.httpClient.get<IAuthUser>(
      `${environment.apiConfig.uri}/authentication/verifyAccess`
    );
  };

  /**
   * Sets the current authenticated user.
   * @param authUser - The user object to be set.
   */
  setUser = (authUser: IUser) => {
    this.authUser = authUser;
  };

  /**
   * Gets the current authenticated user.
   * @returns The user object if authenticated, otherwise fetches user info from MSAL.
   */
  getUser = (): IUser | null => {
    return this.authUser || this.getUserInfo();
  };

  /**
   * Checks if the user is authenticated.
   * @returns A Promise that resolves to a boolean indicating authentication status.
   */
  isAuthenticated = async (): Promise<boolean> => {
    let userInfo = this.getUserInfo() as IUser;

    if (userInfo) {
      if (userInfo.emails && userInfo.rolesOnly) {
        this.appInsights.instance.setAuthenticatedUserContext(
          userInfo.emails[0],
          userInfo.rolesOnly[0]
        );
      }
    }

    return !!this.getUserInfo();
  };

  /**
   * Fetches the user's info from MSAL.
   * @returns The user's info as an `IUser` object or null if not found.
   */
  getUserInfo = (): IUser | null => {
    const userData = this.msalService.instance.getActiveAccount(); // Get all accounts from MSAL
    const account = userData?.idTokenClaims; // Get the first account (if any)

    if (account) {
      const user: IUser = {
        emails: account.emails || [],
        roles: account.roles || "",
        rolesOnly: [] as string[],
        permissionsOnly: [] as string[],
        homeAccountId: "",
        environment: "",
        tenantId: "",
        localAccountId: "",
        username: "",
        city: "",
        aud: "",
        auth_time: 0,
        exp: 0,
        iat: 0,
        iss: "",
        nbf: 0,
        nonce: "",
        tfp: "",
        ver: "",
        ...account,
      };

      const roles = Array.isArray(user.roles)
        ? user.roles
        : user.roles.split(", ");

      if (roles.length > 0) {
        let rolesOnly: string[] = [];
        let permissionsOnly: string[] = [];

        roles.forEach((role: string) => {
          const roleSplitArray = role.split("-");
          if (roleSplitArray[0]) {
            const roleOnly = roleSplitArray[0].toString();
            if (!rolesOnly.includes(roleOnly)) {
              rolesOnly.push(roleOnly);
            }
          }

          if (roleSplitArray[1]) {
            const permissionOnly = roleSplitArray[1].toString();
            if (!permissionsOnly.includes(permissionOnly)) {
              permissionsOnly.push(permissionOnly);
            }
          }
        });

        user.rolesOnly = rolesOnly;
        user.permissionsOnly = permissionsOnly;
      }

      // Set user in the service
      this.setUser(user);
      return user;
    }

    return null;
  };

  /**
   * Extracts permissions from the user's claims.
   * @param claims - The ID token claims.
   * @returns A string array of permissions.
   */
  extractPermissions = (claims: any): string[] => {
    const permissions: string[] = [];
    const roles = claims?.roles || [];

    roles.forEach((role: string) => {
      const roleSplit = role.split("-");
      if (roleSplit[1]) {
        permissions.push(roleSplit[1]);
      }
    });

    return permissions;
  };

  /**
   * Checks if the user has a specific role.
   * @param roleName - The role name to check.
   * @returns A boolean indicating if the user has the role.
   */
  hasRole = (roleName: string): boolean => {
    const user = this.getUser();
    return user?.rolesOnly.includes(roleName) ?? false;
  };

  /**
   * Checks if the user has a specific permission.
   * @param permissionName - The permission name to check.
   * @returns A boolean indicating if the user has the permission.
   */
  hasPermission = (permissionName: string): boolean => {
    const user = this.getUser();
    return user?.permissionsOnly.includes(permissionName) ?? false;
  };

  /**
   * Checks if the current user is an admin based on their roles.
   * @returns A boolean indicating if the user is an admin.
   */
  isAdmin = (): boolean => {
    return (
      this.hasRole("Intl_Admin") ||
      this.hasRole("IWA_Admin") ||
      this.hasRole("IWA_Admin_User")
    );
  };

  isPageReadOnly(pageName: string) {
    let isPageReadOnly: boolean = false;

    switch (pageName) {
      case "Announcements": {
        if (this.hasRole("Intl_User")) {
          isPageReadOnly = true;
        }
        break;
      }
      case "Newsfeed": {
        if (this.hasRole("IA_User")) {
          isPageReadOnly = true;
        }
        break;
      }
      default:
        break;
    }

    // console.log("page: " + pageName + " readOnly property is: " + isPageReadOnly);
    return isPageReadOnly;
  }

  /**
   * Logs out the current user.
   */
  logout(): void {
    this.msalService
      .logoutRedirect({
        account: this.msalService.instance.getActiveAccount()[0],
        postLogoutRedirectUri:
          environment.msalConfig.auth.postLogoutRedirectUri,
      })
      .subscribe({
        next: () => {
          console.log("logout succeed");
          this.authUser = null;
        },
        error: (error) => {
          console.error("Logout failed", error);
        },
      });
  }

  getCacheAccessToken = async (): Promise<string | null> => {
    const scopes = environment.apiConfig.scopes;

    // Get the active account from MSAL service
    const activeAccount: AccountInfo | null =
      this.msalService.instance.getActiveAccount();

    if (!activeAccount) {
      console.error("No active account found");
      return null;
    }

    try {
      // Retrieve access token from MSAL 3.0 cache
      const result: AuthenticationResult =
        await this.msalService.instance.acquireTokenSilent({
          account: activeAccount,
          scopes: scopes,
          authority: environment.msalConfig.auth.authority,
        });

      // Return the access token if present

      return result.accessToken ? result.accessToken : null;
    } catch (error) {
      console.error("Error acquiring token:", error);
      return null;
    }
  };
}
