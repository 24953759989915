import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCard } from "@angular/material/card";
import { RouterLink, RouterOutlet } from "@angular/router";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  InteractionStatus,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { AuthenticationService } from "./shared/auth/auth.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MainNavComponent } from "./shared/components/main-nav/main-nav.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MainNavComponent,
    MatProgressSpinner,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "International Admin Portal";
  displayedName: string | undefined;
  isInvalidLoginAttempt = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;

  isIframe = false;
  loginDisplay = false;
  loadingText: string | undefined;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: AuthenticationService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl("../assets/mdi.svg")
    );
    // console.log("screen width is:" + this.width);
    // console.log("screen height is:" + this.height);
  }

  onWindowResize(event: {
    target: { innerWidth: number; innerHeight: number };
  }) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
  }

  isLoginCompleted(): boolean {
    // Check if there are any active accounts
    const accounts = this.msalService.instance.getAllAccounts();
    return accounts.length > 0;
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnInit() {
    console.log("MSAL Initialization completed");

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    // Subscribe to MSAL events
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.loadingText = "Loading Application Information... Please Wait";

    if (this.isLoginCompleted()) {
      const userInfo = this.authService.getUserInfo();

      if (userInfo) {
        this.displayedName = userInfo.name;
      }
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
