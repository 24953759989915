import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MsalService } from "@azure/msal-angular";

export class DialogModel {
  constructor(public title: string, public message: string) {}
}

export class CustomBox {
  constructor(
    public title: string,
    public message: string,
    public no: string,
    public yes: string,
    public refresh: boolean = true
  ) {}
}

@Component({
  selector: "app-dialog",
  standalone: true,
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class DialogComponent implements OnInit {
  title: string;
  message: string;
  no: string;
  yes: string;
  refresh: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomBox,
    private msalService: MsalService
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.no = data.no;
    this.yes = data.yes;
    this.refresh = data.refresh;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    if (this.no === "logout") {
      this.msalService.logout();
    }
    this.dialogRef.close(this.refresh);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  isHidden() {
    return this.refresh;
  }

  displayTitle() {
    return this.title.length > 0;
  }
}
