import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationService } from "../auth/auth.service";
import { DialogService } from "./dialog.service";
import { environment } from "src/environments/environment";
import { from, Observable, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorHeaderService implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getToken()).pipe(
      switchMap((token) => {
        const headers: { [name: string]: string } = {
          Authorization: `Bearer ${token}`,
        };

        if (environment.apiSubscriptionEnabled) {
          headers[environment.apiSubscriptionKeyName] =
            environment.apiSubscriptionKey;
        }

        const clonedRequest = req.clone({ setHeaders: headers });

        return next.handle(clonedRequest);
      })
    );
  }

  private async getToken(): Promise<string> {
    let token: string | null = await this.authService.getCacheAccessToken();

    if (!token) {
      const authResponse = await this.authService.acquireToken();
      token = authResponse?.accessToken || "";
    }

    return token;
  }
}
