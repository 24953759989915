<div class="seneca-container" id="header">
  <mat-drawer-container class="seneca-sidenav-container">
    <mat-drawer
      #drawer
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)"
      [ngClass]="{
        sideNavExpanded: isExpanded,
        sideNavCollapsed: !isExpanded,
        sideNavTouch: isHandset$ | async
      }"
    >
      <mat-toolbar color="primary" id="top-sidenav" *ngIf="isHandset$ | async">
        <mat-toolbar-row class="seneca-menu-mobile">
          <button
            class="seneca-header-menu mat-icon-button"
            (click)="drawer.toggle(); isExpanded = true"
            onclick="this.blur()"
            autofocus="false"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <div class="header-right-item">ADMIN PORTAL</div>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="divider-20" cdk-focus-start></div>
      <mat-nav-list *ngFor="let link of navLinks">
        <!-- Main Links (Home, Logout) -->
        <mat-list-item
          *ngIf="!showPanelItem(link, isExpanded)"
          routerLinkActive="active-link"
          matTooltip="{{ link.label }}"
          matTooltipPosition="right"
          [matTooltipDisabled]="isExpanded"
        >
          <a class="centered-list-item" [routerLink]="link.link">
            <mat-icon mat-list-icon>{{ link.iconName }}</mat-icon>
            <div class="mat-padding-left" *ngIf="isExpanded">
              <span mat-line>{{ link.label }}</span>
            </div>
          </a>
        </mat-list-item>

        <!-- Expansion Panel for Sub-links -->
        <mat-expansion-panel
          class="mat-elevation-z0"
          *ngIf="showPanelItem(link, isExpanded)"
          [expanded]="activeLinkUrl == link.link"
          routerLinkActive="active-link"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title>
              <mat-icon mat-list-icon>{{ link.iconName }}</mat-icon>
              <div class="mat-padding-left" *ngIf="isExpanded">
                <span mat-line *ngIf="isExpanded">{{ link.label }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Sub-links -->
          <div
            *ngFor="let childLink of link.children"
            class="padding-expansion-body"
          >
            <a [routerLink]="childLink.link" (click)="details(childLink.link)">
              <mat-list-item>
                <div class="expansion-panel-body-padding-left">
                  <span
                    [routerLinkActive]="['active-link', 'active-sub-link']"
                    >{{ childLink.label }}</span
                  >
                </div>
              </mat-list-item>
            </a>
          </div>
        </mat-expansion-panel>
      </mat-nav-list>
    </mat-drawer>
    <!-- *ngIf="_router.url.includes('content')" -->
    <mat-drawer-content
      [ngClass]="{
        sidenavContentExpanded: isExpanded,
        sidenavContentCollapsed: (isHandset$ | async) || !isExpanded,
        sidenavContentMobile: (isHandset$ | async)
      }"
    >
      <mat-toolbar class="seneca-header" color="primary">
        <mat-toolbar-row class="seneca-header-row">
          <span class="header-left-item">
            <div *ngIf="!(isHandset$ | async)">
              <button
                class="seneca-header-menu mat-icon-button"
                (click)="isExpanded = !isExpanded; drawer.open()"
                onclick="this.blur()"
              >
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <div *ngIf="isHandset$ | async">
              <button
                mat-icon-button
                class="seneca-header-menu mat-icon-button"
                (click)="drawer.toggle(); isExpanded = true"
                onclick="this.blur()"
              >
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <div class="seneca-header-logo">
              <img
                src="assets/img/Seneca-International-Horizontal-Logo.svg"
                (click)="onHomeClick()"
              />
            </div>
          </span>
          <div class="header-right-item">ADMIN PORTAL</div>
        </mat-toolbar-row>
      </mat-toolbar>
      <ng-content></ng-content>
      <app-back-to-top
        [acceleration]="2"
        [animate]="true"
        [scrollDistance]="50"
        [speed]="50"
      >
      </app-back-to-top>
      <span id="footer">
        <mat-toolbar>
          <mat-toolbar-row class="seneca-footer-row">
            <div class="seneca-footer-logo">
              <img class="seneca-logo" src="assets/img/seneca-logo-red.png" />
            </div>

            <div class="seneca-footer-middle">
              <span>© 2024. All rights reserved.</span>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </span>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
