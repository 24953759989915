import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../auth/auth.service";

@Component({
  selector: "app-logout",
  standalone: true,
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    console.log("logging out");
    this.authService.logout();
  }
}
